import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";

import Anime from "./pages/anime";
import Home from "./pages/home";
import Navbar from "./components/navbar";
import Search from "./pages/search";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#b8170b",
    },
  },
  components: {
    MuiTypography: {
      h4: {
        color: "#b8170b",
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="anime/:animeID/:pageNumber" element={<Anime />} />
        <Route path="search/:animeSearch" element={<Search />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;