import React, { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";

import Cards from "../components/cards";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [RecentAnime, setRecentAnime] = useState([]);

  const fetchRecentAnime = async () => {
    setLoading(true);
    const result = await fetch("https://api.privatezess.games/recent-release");
    const jsonResult = await result.json();

    setRecentAnime(jsonResult);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    fetchRecentAnime();
  }, []);

  return (
    <Container sx={{ py: 2 }} maxWidth="md">
      <Typography gutterBottom variant="h4" color="primary">
        Recently Updated Anime
      </Typography>
      {Cards(RecentAnime, loading)}
    </Container>
  );
};

export default Home;
