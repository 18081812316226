/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Container,
  Pagination,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import AnimeTypography from "./animeTypography";
import Embed from "./embed";
import error from "../images/Error-404.jpg";

const AnimeViewer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(parseInt(params.pageNumber));
  const [loading, setLoading] = useState(false);
  const [animeInfo, setAnimeInfo] = useState([]);
  const [videoEmbed, setVideoEmbed] = useState([]);

  const getAnimeInfo = async () => {
    setLoading(true);
    const result = await fetch(
      `https://api.privatezess.games/anime-details/${params.animeID}`
    );
    const jsonResult = await result.json();

    setAnimeInfo(jsonResult);
  };

  const getVideoEmbed = async () => {
    setLoading(true);
    const result = await fetch(
      `https://api.privatezess.games/gogo-server/watch/${params.animeID}-episode-${page}`
    );
    const jsonResult = await result.json();
    setVideoEmbed(jsonResult);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  useEffect(() => {
    getAnimeInfo();
    getVideoEmbed();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
    navigate(`/anime/${params.animeID}/${value}`);
  };

  return (
    <>
      <Helmet>
        <title>
          RedAnime - {animeInfo.animeTitle} Episode {page}
        </title>
      </Helmet>
      <Container sx={{ py: 4 }}>
        {!videoEmbed.referer && !loading ? (
          <img src={error} alt="" width="100%" />
        ) : (
          <>
            <Typography
              gutterBottom
              variant="h4"
              component="h4"
              align="center"
              color="primary"
            >
              {loading ? (
                <Skeleton />
              ) : (
                `${animeInfo.animeTitle} | Episode - ${page}`
              )}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              {loading ? (
                <Skeleton width="60%" />
              ) : (
                <Pagination
                  color="primary"
                  page={page ? page : params.pageNumber}
                  onChange={handleChange}
                  count={parseInt(animeInfo.totalEpisodes)}
                  defaultPage={1}
                  boundaryCount={2}
                  showFirstButton
                  showLastButton
                  size="small"
                />
              )}
            </Box>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            ) : (
              Embed(videoEmbed.referer, page)
            )}
            <Box
              display="flex"
              alignItems="left"
              justifyContent="left"
              sx={{ width: "auto" }}
              mt={1}
            >
              <Paper elevation={1}>
                {AnimeTypography("Description", animeInfo.synopsis, loading)}
                {AnimeTypography("Genre", animeInfo.genres, loading)}
                {AnimeTypography("Release", animeInfo.releasedDate, loading)}
                {AnimeTypography("Season", animeInfo.type, loading)}
                {AnimeTypography("Status", animeInfo.status, loading)}
              </Paper>
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default AnimeViewer;
