import * as React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Cards(prop, loading) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={4}>
      {prop.map((info, index) => (
        <Grid item key={index} xs={12} sm={6} md={4}>
          <Card
            sx={{ maxWidth: 480 }}
            onClick={() => 
              info.episodeId === undefined ? navigate(`/anime/${info.animeId}/1`) : navigate(`/anime/${info.slug.replace('https://gogocdn.net/cover/', '')}/1`) }
          >
            <CardActionArea>
              {loading ? (
                <Skeleton sx={{ height: 345 }} />
              ) : (
                <CardMedia
                  component="img"
                  height="345"
                  image={info.animeImg}
                  alt={index}
                />
              )}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {loading ? <Skeleton /> : info.animeTitle}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {(loading && info.episodeNum ) ? <Skeleton /> : (info.episodeId) ? `Episodes: ${info.episodeNum}` : ''}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
