import { Typography, Skeleton } from "@mui/material";

const AnimeTypography = (desc, info, loading) => {
  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        color="#D3D3D3"
        align="justify"
        mt={1}
        sx={{ px: 2 }}
      >
        {desc}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        component="div"
        color="#D3D3D3"
        align="justify"
        mb={1}
        sx={{ px: 2 }}
      >
        {loading ? <Skeleton /> : `${info}`}
      </Typography>
    </>
  );
};

export default AnimeTypography;
