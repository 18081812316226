import css from './embed.css'

const Embed = (url, page) => {
  return (
    <iframe
      src={url}
      width={css.iframe}
      height={css.iframe}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
      key={page}
      title={page}
    />
  );
};

export default Embed;
