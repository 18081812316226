import React from "react";
import AnimeViewer from "../components/animeViewer";

const Anime = () => {
  return (
    <div>
      <AnimeViewer />
    </div>
  );
};

export default Anime;
