/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { Container, Typography } from "@mui/material";

import Cards from "../components/cards";
import error from "../images/Error-404.jpg";

const Search = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [searchAnime, setSearchAnime] = useState([]);

  async function fetchSearchAnime() {
    setLoading(true);
    const result = await fetch(
      `https://api.privatezess.games/search?keyw=${params.animeSearch}`
    );
    const jsonResult = await result.json();

    setSearchAnime(jsonResult);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }

  useEffect(() => {
    fetchSearchAnime();
  }, [params.animeSearch]);

  return (
    <>
      <Helmet>
        <title>RedAnime - Search</title>
      </Helmet>
      <Container sx={{ py: 2 }} maxWidth="md">
        <Typography gutterBottom variant="h4" color="primary">
          Results for {params.animeSearch}
        </Typography>
        {searchAnime.length === 0 && !loading ? (
          <img src={error} alt="" width="100%" />
        ) : null}
        {Cards(searchAnime, loading)}
      </Container>
    </>
  );
};

export default Search;
